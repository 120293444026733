<template>
  <div
    :class="setCellClass()"
    @click="changeSelect(type)"
  >
    {{ value }}
  </div>
</template>

<script>
// import moment from 'moment';

import moment from 'moment';

export default {
  name: 'Cell',
  props: {
    day: Number,
    week: Number,
    month: Number,
    year: Number,
    active: Boolean,
    value: [Number, Object, String],
    fromStart: [Number, Object, String],
    fromEnd: [Number, Object, String],
    type: {
      type: String,
      default: 'day',
    },
  },
  watch: {
    month: function (newVal) {
      this.onStart(newVal, this.year);
    },
    year: function (newVal) {
      this.onStart(this.month, newVal);
    },
    type: function () {
      this.onStart(this.month, this.month);
    },
  },
  data() {
    return {
      activeDate: {},
      disabled: false,
      numberMonth: null,
      months: null,
    };
  },
  methods: {
    setCellClass() {
      let result = 'block__column';
      let start = null;
      let end = null;
      if (this.type !== 'month') {
        start = +this.fromStart.split('.')[0];
        end = +this.fromEnd.split('.')[0];
      } else {
        start = this.fromStart;
        end = this.fromEnd;
      }
      if (this.active && !this.disabled) {
        result += ` calendar__${this.type}_active`;
      }
      result += ` calendar__${this.type}`;
      if (this.type !== 'month') {
        const val = +this.value;
        if (this.disabled && val === start && start !== end) {
          result += ` calendar__${this.type}_disabled calendar__${this.type}_disabled_start`;
        } else if (this.disabled && val === end && start !== end) {
          result += ` calendar__${this.type}_disabled calendar__${this.type}_disabled_end`;
        } else if (this.disabled && start === end) {
          result += ` calendar__${this.type}_disabled calendar__${this.type}_disabled_once`;
        } else if (this.disabled) {
          result += ` calendar__${this.type}_disabled`;
        } else {
          result += '';
        }
      }
      if (this.type === 'month') {
        if (this.disabled && this.months && this.value === this.months[0]) {
          result += ` calendar__${this.type}_disabled calendar__${this.type}_disabled_start`;
        } else if (this.disabled && this.fromEnd === this.value) {
          result += ` calendar__${this.type}_disabled calendar__${this.type}_disabled_end`;
        } else if (this.disabled) {
          result += ` calendar__${this.type}_disabled`;
        } else {
          result += '';
        }
      }
      return result;
    },
    changeSelect(type) {
      if (type === 'day') {
        this.selectDay();
      } else if (type === 'month') {
        this.selectMonth();
      } else if (type === 'year') {
        this.selectYear();
      }
    },
    selectDay() {
      if (!this.disabled && this.value) {
        this.$emit('selectDay', {
          week: this.week,
          day: this.day,
        });
      }
    },
    selectMonth() {
      if (!this.disabled && this.value) {
        this.$emit('selectMonth', this.numberMonth);
      }
    },
    selectYear() {
      if (!this.disabled && this.value) {
        this.$emit('selectYear', Number(this.value));
      }
    },
    setValue(type) {
      let result = this.value;
      if (type === 'day') {
        result = `${this.value}.${this.month}.${this.year}`;
      }
      return result;
    },
    zeroFill(num, len) {
      let res = String(num);
      for (let i = 0; i < len - String(num).length; i++) {
        res = '0' + res;
      }
      return res;
    },
    onStart(month, year) {
      if (this.type === 'day') {
        const end = this.fromEnd.split('.');
        const endDay = Number(end[0]);
        const endMonth = Number(end[1]);
        const endYear = Number(end[2]);
        if (this.value && this.fromEnd && endYear === year
          && endMonth === month && endDay >= this.value) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      } else if (this.type === 'year') {
        if (this.value && Number(this.fromStart) >= Number(this.value)) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      } else if (this.type === 'month') {
        const calendar = moment;
        calendar.locale(this.$store.state.index.language);
        const months = calendar.monthsShort();
        this.months = months;
        const actualYear = new Date().getFullYear();
        const index = months.findIndex((item) => item === this.value);
        this.numberMonth = index + 1;
        if (this.year < +actualYear) {
          this.disabled = true;
        } else if (this.year === +actualYear) {
          if (index + 1 < this.month) {
            this.disabled = true;
          } else {
            this.disabled = false;
          }
        } else {
          this.disabled = false;
        }
      }
    },
  },
  mounted() {
    this.onStart(this.month, this.year);
  },
};
</script>
